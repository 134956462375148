@media only screen and (max-width: 768px) {
  html{
    height: 90vh;
  }
  .githubcontainer {
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center;    /* Vertical centering */
    height: 70vh;
    width: 90vw;
    padding: 1px;
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  }

  .githubinner {
    max-height: 15vh; /* Set a maximum height */
    width: 80vw;
    margin: 0 auto;
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  }
}